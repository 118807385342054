import React, { useEffect } from "react";
import { createContext, useState } from "react";
import { Button, Stack, Dialog, DialogContent } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import Client from "./Client";
import ClientCard from "./ClientCard";
import { ButtonPill } from "../CustomStyled/ButtonPill";
import CreateBundleModal from "../Modals/CreateBundleModal";

export const ClientContext = createContext();

export default function Clients({ reload }) {
  const [open, setOpen] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [idClient, setIdClient] = useState("");
  const [bundles, setBundles] = useState([]);
  const { bearerToken } = useAuth();
  const [showCreateBundleModal, setShowCreateBundleModal] = useState(false);

  useEffect(() => {
    //console.log("State changed in Client component. Now Id is ", idClient);
    if (idClient !== "") {
      setOpen(true);
    }
  }, [idClient]);

  useEffect(() => {
    setOpen(false);
    var init = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ bucket: "viewpoint.0" }),
    };

    fetch(
      "https://tihils596j.execute-api.us-east-1.amazonaws.com/list_bundles",
      init
    )
      .then((response) => response.json())
      .then((data) => {
        const truncated = [];
        //Remove trailing slash from the bundle name
        data.map((d) => truncated.push(d.substring(0, d.length - 1)));
        setBundles(truncated);
      });
  }, []);

  const handleSaveNewBundle = (bundleName, password, description) => {
    const bn = bundleName;

    var init = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        bucket: "viewpoint.0",
        bundle: `${bundleName}`,
        password: `${password}`,
      }),
    };

    fetch(
      "https://tihils596j.execute-api.us-east-1.amazonaws.com/create_bundles",
      init
    ).then(() => {
      var init = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          bucket: "viewpoint.0",
          bundle: `${bundleName}`,
          desc: `${description}`,
        }),
      };
      fetch(
        "https://tihils596j.execute-api.us-east-1.amazonaws.com/write_desc",
        init
      ).then(() => {
        var init = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: `${bn}@viewpoint.studio`,
            password: `${password}`,
            returnSecureToken: false,
          }),
        };
        fetch(
          "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyCGptHVhPMdfa7vIk8tQOHgUTnq3cQZrZc",
          init
        ).then(() => reload());
      });
    });
  };

  /* Modals handlers */
  const toggleNewBundleModal = () => {
    setShowCreateBundleModal(!showCreateBundleModal);
  };

  const handleClose = () => {
    setOpen(false);
    if (dirty) {
      reload();
    }
  };
  const setDirtyField = (value) => {
    setDirty(value);
  };

  return (
    <ClientContext.Provider value={[idClient, setIdClient]}>
      <Stack>
        <ButtonPill
          onClick={toggleNewBundleModal}
          variant="outlined"
          color="secondary"
          style={{
            fontFamily: "avenir",
            width: "186px",
            fontWeight: "900",
            marginLeft: "33px",
          }}
        >
          ADD NEW GROUP
        </ButtonPill>
      </Stack>
      <Stack direction="row" style={{ display: "flex", flexWrap: "wrap" }}>
        {bundles
          .sort((a, b) => {
            return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
          })
          .map((b) => {
            return <ClientCard bucketId="viewpoint.0" Id={b} key={b} />;
          })}
      </Stack>
      {/* Client modal */}
      <Dialog
        sx={{
          ".MuiPaper-root": {
            borderRadius: "20px",
            border: "none",
            height: "655px",
            width: "1044px",
          },
        }}
        fullWidth
        maxWidth="xl"
        open={open}
      >
        <DialogContent>
          <Client
            clientId={idClient}
            setShowClientModal={handleClose}
            setDirtyField={setDirtyField}
          ></Client>
          <Button
            style={{
              position: "absolute",
              right: "18px",
              top: "18px",
              fontFamily: "Avenir",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "19px",
              color: "black",
              border: "none",
              textTransform: "none",
              padding: "18px",
            }}
            variant="outlined"
            onClick={() => {
              handleClose(dirty);
              setIdClient("");
            }}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>

      {/* Create Bundle modal */}
      <CreateBundleModal
        show={showCreateBundleModal}
        handleCancel={toggleNewBundleModal}
        handleSaveNewBundle={handleSaveNewBundle}
      ></CreateBundleModal>
    </ClientContext.Provider>
  );
}
