import React, { useRef, useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import { ButtonPill } from "../CustomStyled/ButtonPill";
import "../../styles/MoveFileModal.css";
import { useAuth } from "../../context/AuthContext";

const styles = {
  paperProps: {
    margin: 0,
    padding: 0,
    width: "550px",
    height: "420px",
    border: "2px solid #4C3AE2",
    borderRadius: "20px",
    boxShadow:
      "2px 2px 20px rgba(0, 0, 0, 0.1), 2px 2px 8px rgba(0, 0, 0, 0.24)",
  },
};

export default function MoveFileModal({
  fileName,
  show,
  handleCancel,
  handleMoveFile,
}) {
  const [bundleSelected, setBundleSelected] = useState("");
  const [bundles, setBundles] = useState([]);
  const { bearerToken } = useAuth();

  useEffect(() => {
    var init = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ bucket: "viewpoint.0" }),
    };

    fetch(
      "https://tihils596j.execute-api.us-east-1.amazonaws.com/list_bundles",
      init
    )
      .then((response) => response.json())
      .then((data) => {
        const truncated = [];
        data.map((d) => truncated.push(d.substring(0, d.length - 1)));
        setBundles(truncated);
      });
  }, []);

  return (
    <div>
      <Dialog
        open={show}
        sx={{
          ".MuiDialogContent-root": {
            width: "100%",
          },
        }}
        PaperProps={{
          style: styles.paperProps,
        }}
      >
        <DialogTitle className="modal-title">MOVE VPT FILE TO:</DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <Stack direction="column">
            <Stack
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "flex-end",
              }}
            >
              <Stack className="modal-bundlename">
                <ul className="bundle-list">
                  {bundles.map((bundle) => (
                    <li
                      key={bundle}
                      className={bundleSelected === bundle ? "selected" : ""}
                      onClick={() => setBundleSelected(bundle)}
                    >
                      {bundle}
                    </li>
                  ))}
                </ul>
              </Stack>
              <Stack className="modal-buttons" direction="row">
                <ButtonPill
                  variant="outlined"
                  color="primary"
                  onClick={handleCancel}
                >
                  CANCEL
                </ButtonPill>
                <ButtonPill
                  variant="outlined"
                  color="primary"
                  onClick={() => handleMoveFile(bundleSelected)}
                >
                  MOVE
                </ButtonPill>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
