import React from "react";
import { Form, Card, NavLink } from "react-bootstrap";
import { useRef, useState } from "react";
import { useAuth } from "../context/AuthContext";
//import { useNavigate } from "react-router-dom";
import { Button, Stack, Alert } from "@mui/material";
// import { auth } from "../firebase";
// import { getAuth, sendSignInLinkToEmail } from "firebase/auth";

export default function Signup() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signIn, /* signOut,*/ currentUser } = useAuth();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  // const [idToken, setIdToken] = useState("");
  //const navigate = useNavigate();

  function showError(error) {
    setError(error);
    setTimeout(() => setError(""), 5000);
  }

  function showSuccess(success) {
    setSuccess(success);
    setTimeout(() => setSuccess(""), 5000);
  }

  async function handleOnSubmit(e) {
    e.preventDefault();
    // let idToken = "";

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      console.log(passwordConfirmRef.current.value, passwordRef.current.value);
      return showError("Password do not match");
    }

    setError("");
    setLoading(true);

    var initSignUp = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: emailRef.current.value,
        password: passwordRef.current.value,
        returnSecureToken: true,
      }),
    };

    await fetch(
      "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyBQ9GBYJJyThL3URWU6vQGU51qarMft27c",
      initSignUp
    )
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          return response.json().then((data) => {
            console.log("response is ok?", response.ok);
            console.log("data", data);
            throw { data };
          });
        }

        signIn(emailRef.current.value, passwordRef.current.value).then(
          (response) => {
            // console.log("currentUser", currentUser);
            // console.log("response", response);
            // console.log("isVerified", response.user.emailVerified);
            // if (!response.user.emailVerified) {
            //   response.user.sendEmailVerification();
            // }
          }
        );

        showSuccess(
          "You have successfully signed up. You can proceed to Login page."
        );
        setLoading(false);
      })
      .catch(({ data }) => {
        console.log("data from catch", data);
        console.log("errorObj(data.error.message)", data.error.message);

        let errorString = JSON.stringify(JSON.stringify(data.error.message));
        console.log("errorString(data.error.message.toString)", errorString);

        if (errorString.indexOf("EMAIL_EXISTS") >= 0) {
          showError("This email has alrady been used");
          setLoading(false);
          return;
        }
        if (errorString.indexOf("WEAK_PASSWORD") >= 0) {
          showError("Password is too weak. Please use at least 6 characters");
          setLoading(false);
          return;
        }
        if (errorString.indexOf("Unauthorized email") >= 0) {
          showError("Only emails from ICS domain are allowed");
          setLoading(false);
          return;
        }
      });
  }

  return (
    <Stack spacing={4}>
      <Card style={{ width: "30%", margin: "auto", marginTop: "200px" }}>
        <Card.Body>
          <h2 className="text-center mb-4">Sign Up</h2>
          {error && (
            <Alert sx={{ marginBottom: "10px" }} severity="error">
              {error}
            </Alert>
          )}
          {success && (
            <Alert sx={{ marginBottom: "10px" }} severity="success">
              {success}
            </Alert>
          )}
          <Form onSubmit={handleOnSubmit}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Form.Group id="password-confirm">
              <Form.Label>Confirm password</Form.Label>
              <Form.Control type="password" ref={passwordConfirmRef} required />
            </Form.Group>
            <Button
              disabled={loading}
              variant="contained"
              className="w-100 mt-4"
              type="submit"
            >
              Sign Up{" "}
            </Button>
          </Form>
          <div className="w-100 text-center mt-2">
            Already have an account?
            <NavLink href="/signin">
              <Button variant="outlined" className="w-100 mt-4">
                LOGIN
              </Button>
            </NavLink>
          </div>
        </Card.Body>
      </Card>
    </Stack>
  );
}
