import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Stack,
  Alert,
} from "@mui/material";
import { ButtonPill } from "../CustomStyled/ButtonPill";
import "../../styles/PasswordChangeModal.css";
import { useAuth } from "../../context/AuthContext";

const styles = {
  paperProps: {
    margin: 0,
    padding: 0,
    width: "650px",
    height: "350px",
    border: "2px solid #4C3AE2",
    borderRadius: "20px",
    boxShadow:
      "2px 2px 20px rgba(0, 0, 0, 0.1), 2px 2px 8px rgba(0, 0, 0, 0.24)",
  },
};

export default function PasswordChangeModal({
  handleChangePassword,
  show,
  handleCancel,
}) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const { bearerToken } = useAuth();

  return (
    <div>
      <Dialog
        open={show}
        sx={{
          ".MuiDialogContent-root": {
            width: "100%",
          },
        }}
        PaperProps={{
          style: styles.paperProps,
        }}
      >
        <DialogTitle className="modal-bundle-title">
          CHANGE PASSWORD
        </DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <Stack direction="column">
            <Stack
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "flex-end",
              }}
            >
              <Stack>
                <TextField
                  InputProps={{
                    autoComplete: "new-password",
                  }}
                  type="text"
                  className="pw-change-password"
                  label="New Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  onKeyUp={() => {
                    if (password.length < 6) {
                      setError(
                        "Password needs to be 6 or more characters long"
                      );
                    } else {
                      if (
                        password !== confirmPassword ||
                        password === "" ||
                        confirmPassword === ""
                      ) {
                        setError(
                          "Password does not match confirmation password"
                        );
                      } else {
                        setError("");
                      }
                    }
                  }}
                  required
                ></TextField>
                <TextField
                  InputProps={{
                    autoComplete: "new-password",
                  }}
                  type="text"
                  className="pw-change-confirm-password"
                  label="Confirm Password"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  onKeyUp={() => {
                    console.log("password.length", password.length);
                    if (password.length < 6) {
                      setError(
                        "Password needs to be 6 or more characters long"
                      );
                    } else {
                      if (
                        password !== confirmPassword ||
                        password === "" ||
                        confirmPassword === ""
                      ) {
                        setError(
                          "Password does not match confirmation password"
                        );
                      } else {
                        setError("");
                      }
                    }
                  }}
                  required
                ></TextField>
                {error && (
                  <Alert
                    className="pw-change-error"
                    sx={{ marginBottom: "10px" }}
                    severity="error"
                  >
                    {error}
                  </Alert>
                )}
              </Stack>
              <Stack className="modal-buttons" direction="row">
                <ButtonPill
                  variant="outlined"
                  color="primary"
                  onClick={handleCancel}
                >
                  CANCEL
                </ButtonPill>
                <ButtonPill
                  variant="outlined"
                  color="primary"
                  onClick={() => handleChangePassword(password)}
                  disabled={
                    error !== "" ||
                    password.length < 6 ||
                    password.confirmPassword < 6
                  }
                >
                  SAVE
                </ButtonPill>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
