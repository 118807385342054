import React, { useContext, useEffect, useState } from "react";
import { auth } from "../firebase";
// import { useNavigate } from "react-router-dom";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [bearerToken, setBearerToken] = useState("");
  // const navigate = useNavigate();

  const value = {
    bearerToken,
    setBearer,
    currentUser,
    signUp,
    signIn,
    signOut,
    forgotPassword,
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      console.log("onAuthChanged user", user);
      setLoading(false);
      console.log("state changed");
      user && user.getIdToken().then((token) => setBearerToken(token));
    });
    return unsubscribe;
  }, []);

  function setBearer(token) {
    setBearerToken(token);
  }
  function signUp(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }
  function signIn(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }
  function forgotPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }
  function signOut() {
    return auth.signOut();
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
