import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const GreyTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    "&::before": {
      backgroundColor: "#D5D5D5",
      border: "1px solid #C6C6C6",
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#D4D4D4",
    color: "#202020",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    fontStyle: "italic",
    border: "1px solid #C6C6C6",
    borderRadius: "20px",
  },
}));

export default GreyTooltip;
