import React, { useEffect, useState, useContext } from "react";
import { Card } from "react-bootstrap";
import { useAuth } from "../../context/AuthContext";
import FileNamesList from "./FileNamesList";
import { ClientContext } from "./Clients";
import GreyTooltip from "../GreyTooltip";
import "../../styles/Clients.css";

export default function ClientCard({ bucketId, Id }) {
  const { bearerToken } = useAuth();
  const [files, setFiles] = useState([]);
  const [id, setId] = useContext(ClientContext);

  useEffect(() => {
    const controller = new AbortController();

    // console.log("Id from CLientContext", id);
    // console.log("Id from CLientCard prop", Id);

    var init = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ bucket: `${bucketId}`, key: `${Id}` }),
      signal: controller.signal,
    };

    fetch(
      "https://tihils596j.execute-api.us-east-1.amazonaws.com/list_objects",
      init
    )
      .then((response) => response.json())
      .then((data) => {
        //Remove desc and password file from the visible files on UI
        const filtered = data.filter(
          (file) => !["desc.txt", "date.txt", "password.txt"].includes(file)
        );
        setFiles(filtered);
      });

    return () => {
      controller.abort();
    };
  }, []);

  return (
    files.length >= 0 && (
      <Card
        className="card-hover"
        onClick={() => {
          setId(Id);
        }}
        style={{
          width: "380px",
          height: "248px",
          marginTop: "2rem",
          marginLeft: "2rem",
          borderRadius: "20px",
        }}
      >
        <Card.Body>
          <Card.Title>
            <span style={{ display: "float", width: "100%" }}>
              <h2
                style={{
                  float: "left",
                  width: "80%",
                  fontSize: "18px",
                  lineHeight: "24px",
                  fontWeight: "800",
                  fontFamily: "avenir",
                  marginLeft: "10px",
                }}
              >
                {Id}
              </h2>
              <GreyTooltip title="Upload new VPT file" placement="top" arrow>
                <h2
                  style={{
                    fontSize: "25px",
                    lineHeight: "15px",
                    fontWeight: "500",
                    float: "right",
                    color: "#4C3BE3",
                    cursor: "pointer",
                  }}
                >
                  +
                </h2>
              </GreyTooltip>
              <br style={{ clear: "both" }} />
            </span>
          </Card.Title>
          <Card.Body style={{ paddingLeft: "10px", paddingTop: "0px" }}>
            <FileNamesList files={files} />
          </Card.Body>
        </Card.Body>
      </Card>
    )
  );
}
