import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Stack,
  Alert,
} from "@mui/material";
import { ButtonPill } from "../CustomStyled/ButtonPill";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "../../styles/RenameBundleModal.css";
import { useAuth } from "../../context/AuthContext";

const styles = {
  paperProps: {
    margin: 0,
    padding: 0,
    width: "650px",
    height: "390px",
    border: "2px solid #4C3AE2",
    borderRadius: "20px",
    boxShadow:
      "2px 2px 20px rgba(0, 0, 0, 0.1), 2px 2px 8px rgba(0, 0, 0, 0.24)",
  },
};

export default function RenameBundleModal({
  setDirtyField,
  bundleName,
  show,
  handleCancel,
  handleRenameBundle,
  desc,
  pass,
}) {
  const [bundleNewName, setBundleNewName] = useState(bundleName);
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [description, setDescription] = useState("");
  const { bearerToken } = useAuth();

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    // let init = {
    //   method: "POST",
    //   headers: {
    //     Authorization: `Bearer ${bearerToken}`,
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ bucket: `viewpoint.0`, bundle: `${bundleName}` }),
    // };
    // fetch(
    //   "https://tihils596j.execute-api.us-east-1.amazonaws.com/read_desc",
    //   init
    // )
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setDescription(data);
    //   });
    // let initPass = {
    //   method: "POST",
    //   headers: {
    //     Authorization: `Bearer ${bearerToken}`,
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ bucket: `viewpoint.0`, bundle: `${bundleName}` }),
    // };
    // fetch(
    //   "https://tihils596j.execute-api.us-east-1.amazonaws.com/read_password",
    //   initPass
    // )
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setPassword(data);
    //   });
    setDescription(desc);
    setPassword(pass);
  }, [desc, pass]);

  return (
    <div>
      {/* Edit group */}
      <Dialog
        open={show}
        sx={{
          ".MuiDialogContent-root": {
            width: "100%",
          },
        }}
        PaperProps={{
          style: styles.paperProps,
        }}
      >
        <DialogTitle className="modal-bundle-title-rename">
          EDIT GROUP
        </DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <Stack direction="column">
            <Stack
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "flex-end",
              }}
            >
              <Stack>
                <TextField
                  inputProps={{
                    autoComplete: "new-password",
                  }}
                  className="bundle-name"
                  label="Group name"
                  onChange={(e) => {
                    setBundleNewName(e.target.value.trim().replace(/ /g, "-"));
                    setDirtyField(true);
                  }}
                  required
                  value={bundleNewName}
                ></TextField>
                <TextField
                  inputProps={{
                    autoComplete: "new-password",
                  }}
                  className="description"
                  label="Description"
                  required
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setDirtyField(true);
                  }}
                ></TextField>

                {error && (
                  <Alert
                    className="error"
                    sx={{ marginBottom: "10px" }}
                    severity="error"
                  >
                    Password and Confirm Password do not match
                  </Alert>
                )}
              </Stack>
              <Stack className="modal-buttons" direction="row">
                <ButtonPill
                  variant="outlined"
                  color="primary"
                  onClick={handleCancel}
                >
                  CANCEL
                </ButtonPill>
                <ButtonPill
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    handleRenameBundle(bundleNewName, password, description)
                  }
                  disabled={
                    error || bundleNewName.length < 3 || description.length < 3
                  }
                >
                  SAVE
                </ButtonPill>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
