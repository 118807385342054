import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Alert,
} from "@mui/material";
import { ButtonPill } from "../CustomStyled/ButtonPill";
import "../../styles/DeleteBundleModal.css";

const styles = {
  paperProps: {
    margin: 0,
    padding: 0,
    width: "550px",
    height: "300px",
    border: "2px solid #4C3AE2",
    borderRadius: "20px",
    boxShadow:
      "2px 2px 20px rgba(0, 0, 0, 0.1), 2px 2px 8px rgba(0, 0, 0, 0.24)",
  },
};

export default function DeleteBundleModal({
  bundleName,
  show,
  handleCancel,
  handleDeleteBundle,
  error,
  setError,
  password,
}) {
  const bundleNameRef = useRef();
  //const [password, setPassword] = useState("");

  return (
    <div>
      <Dialog
        open={show}
        sx={{
          ".MuiDialogContent-root": {
            width: "100%",
          },
        }}
        PaperProps={{
          style: styles.paperProps,
        }}
      >
        <DialogTitle className="delete-bundle-modal-title">
          Are you sure you want to delete the group and all its contents?
        </DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <Stack direction="column">
            <Stack
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "flex-end",
              }}
            >
              <Stack className="delete-bundle-modal-bundlename">
                "{bundleName}"
              </Stack>
              <Stack>
                {/* <TextField
                  type="password"
                  className="delete-bundle-modal-password"
                  label="Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setError("");
                  }}
                  required
                ></TextField> */}
                {error && (
                  <Alert
                    className="error-delete"
                    sx={{ marginBottom: "10px" }}
                    severity="error"
                  >
                    Wrong password.
                  </Alert>
                )}
              </Stack>
              <Stack className="modal-buttons" direction="row">
                <ButtonPill
                  variant="outlined"
                  color="primary"
                  onClick={handleCancel}
                >
                  CANCEL
                </ButtonPill>
                <ButtonPill
                  variant="outlined"
                  color="primary"
                  onClick={() => handleDeleteBundle(password)}
                  disabled={password.length < 6}
                >
                  DELETE
                </ButtonPill>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
