import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { useAuth, currentUser } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Button, Stack } from "@mui/material";
import SearchBar from "./SearchBar";

import "../styles/TopNavigation.css";

export default function TopNavigation() {
  const { currentUser, signOut, setBearer, bearerToken } = useAuth();
  const navigate = useNavigate();

  async function handleSignOut() {
    await signOut();
    navigate("/signin");
  }

  useEffect(() => {
    let intId = setInterval(() => {
      var init = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          grant_type: "refresh_token",
          refresh_token:
            currentUser.multiFactor.user.stsTokenManager.refreshToken,
        }),
      };

      fetch(
        "https://securetoken.googleapis.com/v1/token?key=AIzaSyBQ9GBYJJyThL3URWU6vQGU51qarMft27c",
        init
      )
        .then((response) => response.json())
        .then((data) => {
          //console.log("data", data);
          // console.log("bearerToken before", bearerToken);
          setBearer(data.access_token);
          // console.log("bearerToken after", bearerToken);
        });
    }, 59000);

    return () => {
      clearInterval(intId);
    };
  }, []);

  return (
    currentUser && (
      <Stack>
        <AppBar
          style={{
            backgroundColor: "transparent",
            boxShadow: "none",
            zIndex: 200,
          }} //,
          position="static"
        >
          <Toolbar>
            <Typography style={{ flexGrow: 100 }}></Typography>
            <Stack
              sx={{
                "& button": {
                  background: "none",
                  boxShadow: "none",
                  textTransform: "none",
                  fontFamily: "Avenir",
                },
                "& button:hover": {
                  background: "none",
                  boxShadow: "none",
                },
                "& button:visited": {
                  background: "none",
                  boxShadow: "none",
                },
                "& button:active": {
                  color: "4D3BE2",
                },
                "& button.Mui-selected": {
                  backgroundColor: "white",
                  color: "#4D3BE2",
                },
              }}
              direction="row"
              style={{
                height: "92%",
                borderLeft: "1px solid white",
              }}
            >
              {/* <SearchBar /> */}
              <Button
                className="signOutButton"
                onClick={handleSignOut}
                variant="contained"
                color="secondary"
                type="submit"
                size="medium"
                sx={{
                  fontSize: "15px",
                  padding: "20px 0px 20px 0px",
                  paddingLeft: "20px",
                }}
              >
                Logout
              </Button>
            </Stack>
            {/* <Container className="d-flex">
            <Navbar.Brand href="/">Navbar</Navbar.Brand>

            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
            </Nav>
            <Nav className="navbar-right mt-0">
              <span className="align-items-center  ">
                Welcome {currentUser && currentUser.email}
              </span>
              <Button
                onClick={handleSignOut}
                className="w-300 mt-0"
                variant="contained"
                color="secondary"
                type="submit"
              >
                Sign Out
              </Button>
            </Nav>
          </Container> */}
          </Toolbar>
        </AppBar>
      </Stack>
    )
  );
}
