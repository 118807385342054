import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import { Button } from "@mui/material";
import { ButtonPill } from "../CustomStyled/ButtonPill";
import "../../styles/UploadFileModal.css";
import LinearProgress from "@mui/material/LinearProgress";

export default function UploadFileModal({
  show,
  handleCancelFileUploadModal,
  clientId,
  onUpload,
  fileList,
  progress,
}) {
  const [file, setFile] = useState({});
  const [uploadURL, setUploadURL] = useState("");
  const [filePicked, setFilePicked] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const { bearerToken } = useAuth();
  const [showOverwriteModal, setshowOverwriteModal] = useState(false);
  const fileInputRef = useRef();

  useEffect(() => console.log("useEfect file", file));

  function handleConfirmOverwriteModal() {
    setshowOverwriteModal(false);
  }

  function handleCancelOverwriteModal() {
    setshowOverwriteModal(false);
    setFile(null);
    setFilePicked(false);
    console.log("fileInputRef", fileInputRef);
    fileInputRef.current.value = "";
  }

  function handleFileOnClick(e) {
    console.log(e.target.value);
    e.target.value = "";
    setFilePicked(false);
    console.log(e.target.value);
  }

  function handleFileChange(e) {
    if (e.target.files[0]) {
      console.log("e.target.files[0]", e.target.files[0]);
      setFile(e.target.files[0]);
      //console.log("file", file);
      console.log("file.name", e.target.files[0].name);
      if (fileList.some((f) => f.name === e.target.files[0].name)) {
        setshowOverwriteModal(true);
      }

      console.log(
        "(fileList.some((f) => f.name === e.target.files[0].name)",
        fileList.some((f) => f.name === e.target.files[0].name)
      );
      setFilePicked(true);
      let init = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          bucket: `viewpoint.0`,
          bundle: `${clientId}`,
          object: `${e.target.files[0].name}`,
        }),
      };

      fetch(
        "https://tihils596j.execute-api.us-east-1.amazonaws.com/upload",
        init
      )
        .then((response) => {
          let resp = response.json();
          console.log("response.json()", resp);
          return resp;
        })
        .then((data) => {
          console.log("data", data);
          setUploadURL(data);
        });
    }

    //console.log("uploadURL2", uploadURL);
  }

  function handleUpload(e) {
    e.preventDefault();
    setUploadInProgress(true);

    fetch(uploadURL, {
      method: "PUT",
      body: file,
    })
      .then((res) => {
        console.log("then entered");
        handleCancelFileUploadModal();
        onUpload(file);
        setUploadInProgress(false);
        /* return res.text(); */
      })
      //.then((data) => {})
      .catch((err) => console.error(err));
  }

  return (
    <div>
      <Dialog
        open={show}
        onClose={handleCancelFileUploadModal}
        PaperProps={{
          style: {
            margin: 0,
            padding: 0,
            width: "550px",
            height: "350px",
            border: "2px solid #4C3AE2",
            borderRadius: "20px",
            boxShadow:
              "2px 2px 20px rgba(0, 0, 0, 0.1), 2px 2px 8px rgba(0, 0, 0, 0.24)",
          },
        }}
      >
        <DialogTitle className="upload-file-modal-title">
          Upload file
        </DialogTitle>
        <DialogContent className="upload-form">
          <form>
            <input
              type="file"
              name="presentation"
              onClick={handleFileOnClick}
              onChange={handleFileChange}
              ref={fileInputRef}
            />
            <Button
              onClick={handleUpload}
              disabled={(filePicked ? false : true) || uploadInProgress}
              type="submit"
              variant="contained"
            >
              Upload
            </Button>
            <div
              style={{
                marginTop: "5px",
                display: uploadInProgress ? "block" : "none",
              }}
            >
              <LinearProgress />
            </div>
          </form>
        </DialogContent>
        <Stack direction="row" className="modal-buttons">
          <ButtonPill
            variant="outlined"
            color="primary"
            onClick={handleCancelFileUploadModal}
          >
            Cancel
          </ButtonPill>
        </Stack>
      </Dialog>

      {
        <Dialog
          sx={{
            ".MuiDialogContent-root": {
              width: "100%",
            },
          }}
          PaperProps={{
            style: {
              margin: 0,
              padding: 0,
              width: "550px",
              height: "350px",
              border: "2px solid #4C3AE2",
              borderRadius: "20px",
              boxShadow:
                "2px 2px 20px rgba(0, 0, 0, 0.1), 2px 2px 8px rgba(0, 0, 0, 0.24)",
            },
          }}
          open={showOverwriteModal}
          onClose={handleCancelOverwriteModal}
        >
          <DialogTitle className="upload-file-modal-title">
            Are you sure you want to overwrite the VPT?
          </DialogTitle>
          <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
            <Stack
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "flex-end",
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  fontWeight: "800",
                  height: "50%",
                  textAlign: "center",
                  paddingTop: "10%",
                }}
              ></Stack>
              <Stack direction="row" className="modal-buttons">
                <ButtonPill
                  variant="outlined"
                  color="primary"
                  onClick={handleCancelOverwriteModal}
                >
                  CANCEL
                </ButtonPill>
                <ButtonPill
                  variant="outlined"
                  color="primary"
                  onClick={handleConfirmOverwriteModal}
                >
                  OK
                </ButtonPill>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      }

      {/* <OverwriteModal
        show={showOverwriteModal}
        handleCancelOverWriteModal={() => setshowOverwriteModal(false)}
      /> */}
    </div>
  );
}
