import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

export const ButtonPill = styled(Button)(({ color }) => ({
  color: color,
  "&:hover": { border: "2px solid #202020" },
  border: "2px solid #202020",
  width: "10rem",
  borderRadius: "30px",
  color: "#000",
  width: "140px",
  height: "38px",
  marginLeft: "10px",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "19px",
}));
