import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import { ButtonPill } from "../CustomStyled/ButtonPill";
import "../../styles/DeleteFileModal.css";

export default function DeleteFileModal({
  show,
  vptName,
  handleDeleteFileConfirmation,
  handleCancelDeleteFile,
}) {
  return (
    <div>
      <Dialog
        sx={{
          ".MuiDialogContent-root": {
            width: "100%",
          },
        }}
        open={show}
        onClose={handleCancelDeleteFile}
        PaperProps={{
          style: {
            margin: 0,
            padding: 0,
            width: "550px",
            height: "350px",
            border: "2px solid #4C3AE2",
            borderRadius: "20px",
            boxShadow:
              "2px 2px 20px rgba(0, 0, 0, 0.1), 2px 2px 8px rgba(0, 0, 0, 0.24)",
          },
        }}
      >
        <DialogTitle className="delete-file-modal-title">
          Are you sure you want to delete VPT?
        </DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <Stack
            direction="column"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "flex-end",
            }}
          >
            <Stack className="modal-filename">"{vptName}"</Stack>
            <Stack className="modal-buttons" direction="row">
              <ButtonPill
                variant="outlined"
                color="primary"
                onClick={handleCancelDeleteFile}
              >
                CANCEL
              </ButtonPill>
              <ButtonPill
                variant="outlined"
                color="primary"
                onClick={handleDeleteFileConfirmation}
              >
                DELETE
              </ButtonPill>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
