import Signup from "./components/Signup";
import { Container } from "react-bootstrap";
import AuthProvider from "./context/AuthContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Signin from "./components/Signin";
import Client from "./components/Clients/Client";
import PrivateRoute from "./components/PrivateRoute";
import TopNavigation from "./components/TopNavigation";
import { createTheme, ThemeProvider } from "@mui/material";
import TopBar from "./components/Structure/TopBar";
import ForgotPassword from "./components/ForgotPassword";

const theme = createTheme({
  palette: { primary: { main: "#4D3BE2" }, secondary: { main: "#111111" } },
  typography: {
    h6: { fontSize: "14px", lineHeight: "24px", fontWeight: "400" },
    h1: {
      fontFamily: "Avenir",
      fontSize: "24px",
      lineHeight: "33px",
      fontWeight: "800",
      display: "block",
      alignItems: "center",
      textAlign: "center",
      margin: "0px auto",
    },
  },
});

function App() {
  return (
    <TopBar>
      <ThemeProvider theme={theme}>
        <Router>
          <AuthProvider>
            <TopNavigation />
            <Container className="align-items-center justify-content-center">
              <div
                style={{
                  margin: "0px auto",
                  width: "100%",
                }}
              >
                <Routes>
                  <Route
                    path="/"
                    element={
                      <PrivateRoute>
                        <Home />
                      </PrivateRoute>
                    }
                  />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/signin" element={<Signin />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/clients/:clientId" element={<Client />} />
                </Routes>
              </div>
            </Container>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </TopBar>
  );
}

export default App;
