import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Stack,
  Alert,
} from "@mui/material";
import { ButtonPill } from "../CustomStyled/ButtonPill";
import "../../styles/CreateBundleModal.css";

const styles = {
  paperProps: {
    margin: 0,
    padding: 0,
    width: "650px",
    height: "600px",
    border: "2px solid #4C3AE2",
    borderRadius: "20px",
    boxShadow:
      "2px 2px 20px rgba(0, 0, 0, 0.1), 2px 2px 8px rgba(0, 0, 0, 0.24)",
  },
};

export default function CreateBundleModal({
  show,
  handleCancel,
  handleSaveNewBundle,
}) {
  const [bundleName, setbundleName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");

  return (
    <div>
      {/* Create group */}
      <Dialog
        open={show}
        sx={{
          ".MuiDialogContent-root": {
            width: "100%",
          },
        }}
        PaperProps={{
          style: styles.paperProps,
        }}
      >
        <DialogTitle className="modal-bundle-title">ADD NEW GROUP</DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <Stack direction="column">
            <Stack>
              <TextField
                inputProps={{
                  autoComplete: "new-password",
                }}
                className="bundle-name"
                label="Group name"
                onChange={(e) =>
                  setbundleName(e.target.value.trim().replace(/ /g, "-"))
                }
                required
              ></TextField>
              <TextField
                inputProps={{
                  autoComplete: "new-password",
                }}
                className="description"
                label="Description"
                onChange={(e) => setDescription(e.target.value)}
                required
              ></TextField>
              <TextField
                InputProps={{
                  autoComplete: "new-password",
                }}
                type="text"
                className="password"
                label="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                onKeyUp={() => {
                  if (password.length < 6) {
                    setError("Password needs to be 6 or more characters long");
                  } else {
                    if (
                      password !== confirmPassword ||
                      password === "" ||
                      confirmPassword === ""
                    ) {
                      setError("Password does not match confirmation password");
                    } else {
                      setError("");
                    }
                  }
                }}
                required
              ></TextField>
              <TextField
                InputProps={{
                  autoComplete: "new-password",
                }}
                type="text"
                className="confirm-password"
                label="Confirm Password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                onKeyUp={() => {
                  console.log("password.length", password.length);
                  if (password.length < 6) {
                    setError("Password needs to be 6 or more characters long");
                  } else {
                    if (
                      password !== confirmPassword ||
                      password === "" ||
                      confirmPassword === ""
                    ) {
                      setError("Password does not match confirmation password");
                    } else {
                      setError("");
                    }
                  }
                }}
                required
              ></TextField>
              {error && (
                <Alert
                  className="error"
                  sx={{ marginBottom: "10px" }}
                  severity="error"
                >
                  {error}
                </Alert>
              )}
            </Stack>
            <Stack
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "flex-end",
              }}
            >
              <Stack className="modal-buttons" direction="row">
                <ButtonPill
                  variant="outlined"
                  color="primary"
                  onClick={handleCancel}
                >
                  CANCEL
                </ButtonPill>
                <ButtonPill
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    handleSaveNewBundle(bundleName, password, description)
                  }
                  disabled={
                    error !== "" ||
                    password.length < 6 ||
                    password.confirmPassword < 6
                  }
                >
                  SAVE
                </ButtonPill>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
