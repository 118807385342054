import React from "react";
import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import {
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
  Box,
  CardActions,
} from "@mui/material";
// import TabsNavigation from "../Structure/Tabs/TabsNavigation";
import DriveFileMoveOutlined from "@mui/icons-material/DriveFileMoveOutlined";
import "../styles/Library.css";
import DeleteIcon from "../icons/DeleteIcon";

//Styles
const styles = {
  actionIcon: {
    border: "none",
    background: "none",
    color: "#4C3BE3",
    cursor: "pointer",
  },
};

export default function Library() {
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [showVptDeleteModal, setShowVptDeleteModal] = useState(false);
  const [file, setFile] = useState({});
  const [filesWithMeta, setFilesWithMeta] = useState([]);
  const [fileForDeletion, setFileForDeletion] = useState("");

  const [info, setInfo] = useState([
    {
      clientAssigned: "Elemental Analysis",
      clientName: "Thermo Fisher 1",
      fileSize: "1.5G",
      dateCreated: "02/12/2022",
    },
    {
      clientAssigned: "Elemental Analysis",
      clientName: "Thermo Fisher 2",
      fileSize: "1.5G",
      dateCreated: "02/12/2022",
    },
    {
      clientAssigned: "Elemental Analysis",
      clientName: "Thermo Fisher 3",
      fileSize: "1.5G",
      dateCreated: "02/12/2022",
    },
    {
      clientAssigned: "Elemental Analysis",
      clientName: "Thermo Fisher 4",
      fileSize: "1.5G",
      dateCreated: "02/12/2022",
    },
    {
      clientAssigned: "Elemental Analysis",
      clientName: "Thermo Fisher 5",
      fileSize: "1.5G",
      dateCreated: "02/12/2022",
    },
  ]);

  return (
    <Stack>
      <Box style={{ padding: "9px 20px" }}>
        <Stack direction={"column"}>
          <Typography
            variant="h4"
            style={{
              fontFamily: "avenir",
              textTransform: "uppercase",
              fontWeight: "800",
            }}
            component="div"
          ></Typography>
          {/* edit and delete icons for bundle to be added */}
        </Stack>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 20px 20px 20px",
        }}
      ></Box>
      <Box>
        <Stack></Stack>

        <Table>
          <thead>
            <tr>
              <th className="grayScaleLabel">VPT file</th>
              <th className="grayScaleLabel">Bundle</th>
              <th className="grayScaleLabel">File Size</th>
              <th className="grayScaleLabel">Date Created</th>
              <th className="grayScaleLabel">Delete</th>
              <th className="grayScaleLabel">Move</th>
            </tr>
          </thead>
          <tbody>
            {info.map((file) => {
              return (
                <tr key={file.id}>
                  <td> {file.clientAssigned}</td>
                  <td> {file.clientName}</td>
                  <td>{file.fileSize}</td>
                  <td>
                    {file.dateCreated &&
                      new Date(file.dateCreated)
                        .toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })
                        .split("T")[0]}
                    <br />
                    {
                      new Date(file.dateCreated)
                        .toLocaleTimeString("en-US", {
                          hour: "numeric",
                          minute: "2-digit",
                          second: "2-digit",
                          timeZoneName: "short",
                        })
                        .split("T")[0]
                    }
                  </td>
                  <td>
                    <DeleteIcon
                      style={styles.actionIcon}
                      onClick={() => {
                        setShowVptDeleteModal(true);
                        setFileForDeletion(file.name);
                      }}
                      // {() => handleDelete(clientId, file.name)}
                    />
                  </td>
                  <td>
                    <DriveFileMoveOutlined style={styles.actionIcon} />
                  </td>
                  {/* <td>
                      <button
                        onClick={() => {
                          handleDownload(clientId, file.name);
                        }}
                      >
                        Download
                      </button>
                    </td> */}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Box>
    </Stack>
  );

  // return (
  //   <table style={{ width: "100%" }}>
  //     <thead>
  //       <tr>
  //         <th>Client Assigned To</th>
  //         <th>Client Name</th>
  //         <th>Filesize</th>
  //         <th>Date Created</th>
  //         <th></th>
  //       </tr>
  //     </thead>
  //     <tbody>
  //       {info.map((i) => {
  //         return (
  //           <tr key={i.clientName}>
  //             <td>{i.clientAssigned}</td>
  //             <td>{i.clientName}</td>
  //             <td>{i.fileSize}</td>
  //             <td>{i.dateCreated}</td>
  //             <td>
  //               <DeleteOutlined /> <EditOutlined />
  //             </td>
  //           </tr>
  //         );
  //       })}
  //     </tbody>
  //   </table>
  // );
}
