import React from "react";
import "../../styles/FileNamesList.css";

export default function FileNamesList({ files }) {
  const firstSixFiles = files.slice(0, 6);

  return (
    <div>
      <h2 className="number-of-files">{`Files Available (${files.length})`}</h2>
      <div style={{ height: "140px" }}>
        <ul style={{ listStyle: "none", padding: "0px" }}>
          {firstSixFiles.map((file) => {
            return (
              <li className="list-item" key={file}>
                <span
                  style={{
                    background: "white",
                    paddingRight: "3px",
                    fontSize: "15px",
                  }}
                >
                  {file}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
      {files.length > 6 && (
        <h2
          className="see-more"
          style={{ position: "relative", bottom: "0px", textAlign: "right" }}
        >{`See more`}</h2>
      )}
    </div>
  );
}
