import React from "react";

export default function TopBar({ children }) {
  return (
    <div id="topBar">
      <div id="logo">
        <img src="/images/topBar/logoWhite.svg" />
      </div>
      {children}
    </div>
  );
}
