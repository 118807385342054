import React from "react";
import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import {
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
  Box,
} from "@mui/material";

//icons
import DriveFileMoveOutlined from "@mui/icons-material/DriveFileMoveOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteIcon from "../../icons/DeleteIcon";

import UploadFileModal from "../Modals/UploadFileModal";
import DeleteFileModal from "../Modals/DeleteFileModal";
import DeleteBundleModal from "../Modals/DeleteBundleModal";
import RenameBundleModal from "../Modals/RenameBundleModal";
import PasswordChangeModal from "../Modals/PasswordChangeModal";
import { useAuth } from "../../context/AuthContext";
import { ButtonPill } from "../CustomStyled/ButtonPill";
import "../../styles/Client.css";
import MoveFileModal from "../Modals/MoveFileModal";

//Styles
const styles = {
  actionIcon: {
    border: "none",
    background: "none",
    color: "#4C3BE3",
    cursor: "pointer",
    margin: "0px 0px 0px 20px",
  },
  actionIconSmall: {
    border: "none",
    background: "none",
    color: "#4C3BE3",
    cursor: "pointer",
    margin: "0px 0px 5px 5px",
    width: "20px",
  },
};

export default function Client({
  clientId,
  setShowClientModal,
  setDirtyField,
}) {
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [showDeleteFileModal, setShowDeleteFileModal] = useState(false);
  const { bearerToken } = useAuth();
  const [file, setFile] = useState({});
  const [filesWithMeta, setFilesWithMeta] = useState([]);
  const [fileForDeletion, setFileForDeletion] = useState("");
  const [fileToMove, setFileToMove] = useState("");
  const [showDeleteBundleModal, setShowDeleteBundleModal] = useState(false);
  const [showRenameBundleModal, setShowRenameBundleModal] = useState(false);
  const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);
  const [showMoveFileModal, setShowMoveFileModal] = useState(false);
  const [info, setInfo] = useState("");
  const [description, setDescription] = useState("");
  const [dateCreated, setDateCreated] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  function showInfo(info) {
    setInfo(info);
    setTimeout(() => setInfo(""), 5000);
  }

  function toggleUploadFileModal() {
    setShowUploadFileModal(!showUploadFileModal);
  }

  function toggleDeleteFileModal() {
    setShowDeleteFileModal(!showDeleteFileModal);
  }

  const toggleDeleteBundleModal = () => {
    setShowDeleteBundleModal(!showDeleteBundleModal);
  };

  const toggleRenameBundleModal = () => {
    setShowRenameBundleModal(!showRenameBundleModal);
  };

  const toggleChangePasswordModal = () => {
    setShowPasswordChangeModal(!showPasswordChangeModal);
  };

  const toggleMoveFileModal = () => {
    setShowMoveFileModal(!showMoveFileModal);
  };

  function onUpload(file) {
    setDirtyField(true);
    setFile({
      id: Math.floor(Math.random() * 1000000),
      name: file.name,
      date: "",
      size: "",
    });
  }

  const handleDelete = (clientId, fileName) => {
    console.log(`handleDelete ${clientId}/${fileName}`);
    var init = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        bucket: `viewpoint.0`,
        bundle: `${clientId}`,
        object: `${fileName}`,
      }),
    };

    fetch("https://tihils596j.execute-api.us-east-1.amazonaws.com/delete", init)
      .then((response) => {
        const updatedFilesList = filesWithMeta.filter((f) => f !== fileName);
        setFilesWithMeta([...updatedFilesList]);
        setFile({});
        return response.json();
      })
      .then(() => {
        setDirtyField(true);
      });
  };

  const handleDeleteBundle = (password) => {
    setError("");
    var init = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: `${clientId}@viewpoint.studio`,
        password: `${password}`,
        returnSecureToken: true,
      }),
    };
    /* Login */
    fetch(
      "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCGptHVhPMdfa7vIk8tQOHgUTnq3cQZrZc",
      init
    )
      .then((result) => {
        return result.json();
      })
      .then((data) => {
        console.log("data.error", data.error);
        if (data.error) {
          throw Error(`${data.error.message}`);
        }
        var init = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            idToken: `${data.idToken}`,
          }),
        };
        /* Delete user */
        fetch(
          "https://identitytoolkit.googleapis.com/v1/accounts:delete?key=AIzaSyCGptHVhPMdfa7vIk8tQOHgUTnq3cQZrZc",
          init
        ).then(() => {
          var init = {
            method: "POST",
            headers: {
              Authorization: `Bearer ${bearerToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              bucket: "viewpoint.0",
              bundle: `${clientId}`,
            }),
          };
          /* Delete bundle */
          fetch(
            "https://tihils596j.execute-api.us-east-1.amazonaws.com/delete_bundles",
            init
          ).then(() => {
            setShowDeleteBundleModal(false);
            setShowClientModal(false);
          });
        });
      })
      .catch((ex) => {
        console.log("exception", ex);
        setError(ex);
      });
  };

  const handleRenameBundle = (bundleNewName, password, description) => {
    var init = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        bucket: "viewpoint.0",
        old_bundle: `${clientId}`,
        new_bundle: `${bundleNewName}`,
      }),
    };

    fetch(
      "https://tihils596j.execute-api.us-east-1.amazonaws.com/rename_bundles",
      init
    ).then(() => {
      var init = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: `${clientId}@viewpoint.studio`,
          password: `${password}`,
          returnSecureToken: true,
        }),
      };
      fetch(
        "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCGptHVhPMdfa7vIk8tQOHgUTnq3cQZrZc",
        init
      )
        .then((result) => {
          return result.json();
        })
        .then((data) => {
          var init = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: `${bundleNewName}@viewpoint.studio`,
              idToken: `${data.idToken}`,
            }),
          };
          fetch(
            "https://identitytoolkit.googleapis.com/v1/accounts:update?key=AIzaSyCGptHVhPMdfa7vIk8tQOHgUTnq3cQZrZc",
            init
          )
            .then((result) => {
              return result.json();
            })
            .then((data) => {
              var init = {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${bearerToken}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  bucket: "viewpoint.0",
                  bundle: `${bundleNewName}`,
                  desc: `${description}`,
                }),
              };
              fetch(
                "https://tihils596j.execute-api.us-east-1.amazonaws.com/write_desc",
                init
              ).then(() => {
                setDirtyField(true);
                setShowRenameBundleModal(false);
                setShowClientModal(false);
              });
            });
        });
    });
  };

  const handleMoveFile = (targetBundle) => {
    var init = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        bucket: `viewpoint.0`,
        object: `${fileToMove}`,
        old_bundle: `${clientId}`,
        new_bundle: `${targetBundle}`,
      }),
    };
    console.log(init);
    fetch(
      "https://tihils596j.execute-api.us-east-1.amazonaws.com/move_objects",
      init
    )
      .then((response) => {
        setFile({});
        setFileToMove("");
        toggleMoveFileModal();
        return response.json();
      })
      .then((data) => {
        setDirtyField(true);
      });
  };

  const handleChangePassword = (newPassword) => {
    //Prvo firebase prod signin

    var init = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: `${clientId}@viewpoint.studio`,
        returnSecureToken: `true`,
        password: `${password}`, //`5555`,
      }),
    };
    fetch(
      "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCGptHVhPMdfa7vIk8tQOHgUTnq3cQZrZc",
      init
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            throw { data };
          });
        }
        return response.json();
      })
      .then((data) => {
        //Update pw on Firebase user
        var init = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            idToken: `${data.idToken}`,
            password: `${newPassword}`,
            returnSecureToken: `true`,
          }),
        };
        fetch(
          "https://identitytoolkit.googleapis.com/v1/accounts:update?key=AIzaSyCGptHVhPMdfa7vIk8tQOHgUTnq3cQZrZc",
          init
        ).then(() => {
          //Change bundle password
          var init = {
            method: "POST",
            headers: {
              Authorization: `Bearer ${bearerToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              bucket: "viewpoint.0",
              bundle: `${clientId}`,
              new_pw: `${newPassword}`,
            }),
          };

          fetch(
            "https://tihils596j.execute-api.us-east-1.amazonaws.com/change_bundle_password",
            init
          ).then(() => {
            setShowPasswordChangeModal(false);
            setShowClientModal(false);
          });
        });
      })
      .catch((response) => {
        console.log("error", response);
      });
  };

  // function handleDownload(clientId, fileName) {
  //   console.log(`handleDownload ${clientId}/${fileName}`);

  //   var init = {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${bearerToken}`,
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ bucket: `${clientId}`, key: `${fileName}` }),
  //   };

  //   fetch(
  //     "https://tihils596j.execute-api.us-east-1.amazonaws.com/download",
  //     init
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       var initDownload = {
  //         method: "GET",
  //         headers: {},
  //       };

  //       fetch(`${data}`, initDownload)
  //         .then((response) => response.blob())
  //         .then((blob) => {
  //           // Create blob link to download
  //           const url = window.URL.createObjectURL(new Blob([blob]));
  //           const link = document.createElement("a");
  //           link.href = url;
  //           link.setAttribute("download", `${fileName}`);

  //           // Append to html link element page
  //           document.body.appendChild(link);

  //           // Start download
  //           link.click();

  //           // Clean up and remove the link
  //           link.parentNode.removeChild(link);
  //         });
  //     });
  // }

  //Read Desc.txt file
  //Read Date.txt file
  useEffect(() => {
    let date = localStorage.getItem(`dateCreated_${clientId}`);
    if (date) {
      setDateCreated(date);
    } else {
      let init = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ bucket: `viewpoint.0`, bundle: `${clientId}` }),
      };

      fetch(
        "https://tihils596j.execute-api.us-east-1.amazonaws.com/read_date",
        init
      )
        .then((response) => response.json())
        .then((data) => {
          localStorage.setItem(`dateCreated_${clientId}`, data);
          setDateCreated(data);
        });
    }

    let init = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ bucket: `viewpoint.0`, bundle: `${clientId}` }),
    };

    fetch(
      "https://tihils596j.execute-api.us-east-1.amazonaws.com/read_desc",
      init
    )
      .then((response) => response.json())
      .then((data) => {
        setDescription(data);
      });
  }, []);

  //Read password.txt file
  useEffect(() => {
    let init = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ bucket: `viewpoint.0`, bundle: `${clientId}` }),
    };

    fetch(
      "https://tihils596j.execute-api.us-east-1.amazonaws.com/read_password",
      init
    )
      .then((response) => response.json())
      .then((data) => {
        setPassword(data);
      });
  }, []);

  useEffect(() => {
    async function getData() {
      const response = [];
      let initMetadataCall = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ bucket: `viewpoint.0`, key: `${clientId}` }),
      };

      const result = await fetch(
        "https://tihils596j.execute-api.us-east-1.amazonaws.com/metadata_objects",
        initMetadataCall
      );
      const jsonData = await result.json();
      console.log("jsonData", jsonData);
      jsonData
        .filter(
          (file) =>
            !["desc.txt", "date.txt", "password.txt"].includes(file.filename)
        )
        .map((file) => {
          response.push({
            id: Math.floor(Math.random() * 10000000),
            name: file.filename,
            dateCreated: file.lastModified,
            size: file.size,
          });
        });

      setFilesWithMeta(response);
    }
    getData();
  }, [file]);

  return (
    filesWithMeta && (
      <Stack>
        <Box style={{ padding: "9px 20px" }}>
          <Stack direction={"column"}>
            <Typography
              variant="h4"
              style={{
                fontFamily: "avenir",
                fontWeight: "800",
                display: "inline",
              }}
              component="div"
            >
              {clientId}

              <ModeEditOutlinedIcon
                style={styles.actionIcon}
                onClick={() => {
                  setShowRenameBundleModal(true);
                }}
              />
              <DeleteIcon
                style={styles.actionIcon}
                onClick={() => {
                  setShowDeleteBundleModal(true);
                }}
                // {() => handleDelete(clientId, file.name)}
              />
            </Typography>

            {/* edit and delete icons for bundle to be added */}
          </Stack>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 20px 20px 20px",
          }}
        >
          <Typography variant="h6" component="span">
            <span className="grayScaleLabel">Date Created:</span>{" "}
            {new Date(dateCreated).toLocaleDateString("en-US")}
          </Typography>
          <Typography variant="h6" component="span">
            <span className="grayScaleLabel">Description:</span> {description}
          </Typography>
          <Typography variant="h6" component="span">
            <span className="grayScaleLabel">Password:</span> {password}
            <ModeEditOutlinedIcon
              style={styles.actionIconSmall}
              onClick={() => {
                toggleChangePasswordModal(true);
              }}
            />
          </Typography>
          <br />
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "left",
            paddingLeft: "5px",
            marginBottom: "10px",
          }}
        >
          {/* <div style={{ alignItems: "flex-start" }}> */}
          <ButtonPill
            onClick={toggleUploadFileModal}
            variant="outlined"
            color="secondary"
            style={{
              fontFamily: "avenir",
              width: "120px",
              fontWeight: "900",
            }}
          >
            ADD VPT
          </ButtonPill>
        </Box>
        <Box style={{ padding: "0px 20px 20px 20px" }}>
          <Stack></Stack>

          <Table>
            <thead>
              <tr>
                <th className="grayScaleLabel">
                  VPT files ({filesWithMeta.length})
                </th>
                <th className="grayScaleLabel">Filesize</th>
                <th className="grayScaleLabel">Date uploaded</th>
                <th className="grayScaleLabel">Delete</th>
                <th className="grayScaleLabel">Move</th>
              </tr>
            </thead>
            <tbody>
              {filesWithMeta.map((file) => {
                return (
                  <tr className="list-item" key={file.id}>
                    <td>
                      <span>{file.name}</span>
                    </td>
                    <td>
                      {file.size && Math.round(file.size / 1024 / 1024)} MB
                    </td>
                    <td>
                      {file.dateCreated &&
                        new Date(file.dateCreated)
                          .toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          })
                          .split("T")[0]}
                      <br />
                      {
                        new Date(file.dateCreated)
                          .toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "2-digit",
                            second: "2-digit",
                            timeZoneName: "short",
                          })
                          .split("T")[0]
                      }
                    </td>
                    <td>
                      <DeleteIcon
                        style={styles.actionIcon}
                        onClick={() => {
                          setShowDeleteFileModal(true);
                          setFileForDeletion(file.name);
                        }}
                        // {() => handleDelete(clientId, file.name)}
                      />
                    </td>
                    <td>
                      <DriveFileMoveOutlined
                        onClick={() => {
                          toggleMoveFileModal();
                          setFileToMove(file.name);
                        }}
                        style={styles.actionIcon}
                      />
                    </td>
                    {/* <td>
                      <button
                        onClick={() => {
                          handleDownload(clientId, file.name);
                        }}
                      >
                        Download
                      </button>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Box>

        {/* Modals */}
        <UploadFileModal
          show={showUploadFileModal}
          clientId={clientId}
          handleCancelFileUploadModal={toggleUploadFileModal}
          onUpload={onUpload}
          fileList={filesWithMeta}
        />

        <DeleteFileModal
          show={showDeleteFileModal}
          handleDeleteFileConfirmation={() => {
            handleDelete(clientId, fileForDeletion);
            toggleDeleteFileModal();
          }}
          handleCancelDeleteFile={toggleDeleteFileModal}
          vptName={fileForDeletion}
        />

        <DeleteBundleModal
          bundleName={clientId}
          show={showDeleteBundleModal}
          handleCancel={toggleDeleteBundleModal}
          handleDeleteBundle={handleDeleteBundle}
          error={error}
          password={password}
          setError={setError}
        />

        <RenameBundleModal
          bundleName={clientId}
          setDirtyField={setDirtyField}
          show={showRenameBundleModal}
          handleCancel={toggleRenameBundleModal}
          handleRenameBundle={handleRenameBundle}
          desc={description}
          pass={password}
        />

        <PasswordChangeModal
          show={showPasswordChangeModal}
          handleCancel={toggleChangePasswordModal}
          handleChangePassword={handleChangePassword}
        />

        <MoveFileModal
          bundleName={clientId}
          show={showMoveFileModal}
          handleCancel={toggleMoveFileModal}
          handleMoveFile={handleMoveFile}
          fileName={fileToMove}
        />
      </Stack>
    )
  );
}
