import React from "react";
import { Form, Card, NavLink } from "react-bootstrap";
import { Button, Stack, TextField, Typography, Alert } from "@mui/material";
import { useRef, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { ButtonPill } from "./CustomStyled/ButtonPill";

export default function Signin() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { signIn } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function showError(error) {
    setError(error);
    setTimeout(() => setError(""), 5000);
  }

  async function handleOnSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await signIn(emailRef.current.value, passwordRef.current.value).then(
        (response) => {
          navigate("/");
          setLoading(false);
        }
      );
    } catch (error) {
      console.log("errorObj", error);

      let errorString = JSON.stringify(JSON.stringify(error));
      console.log("errorString", errorString);

      if (
        errorString.indexOf("Email has to be verified before first signin" >= 0)
      ) {
        showError("Unverified email. Please verify.");
        setLoading(false);
        return;
      }
    }
  }

  return (
    <Stack spacing={4}>
      <div style={{ marginTop: "150px" }}>
        <div>
          <Typography variant="h3" style={{ textAlign: "center" }}>
            CONTENT MANAGEMENT
          </Typography>
        </div>
        <div>
          <Typography
            variant="h4"
            style={{
              textAlign: "center",
              marginTop: "100px",
              fontWeight: "bold",
            }}
          >
            LOGIN
          </Typography>
        </div>
        <div>
          <Card
            style={{
              width: "30%",
              margin: "auto",
              marginTop: "70px",
              border: "none",
            }}
          >
            {error && <Alert severity="error">{error}</Alert>}
            <Card.Body>
              <Form onSubmit={handleOnSubmit}>
                <Stack spacing={2}>
                  <TextField
                    label="Email"
                    inputRef={emailRef}
                    required
                  ></TextField>
                  <TextField
                    label="Password"
                    inputRef={passwordRef}
                    required
                    type="password"
                  ></TextField>
                </Stack>
                <NavLink
                  style={{
                    color: "#870CC9",
                    fontFamily: "Avenir",
                    fontSize: "14px",
                    fontStyle: "Italic",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px",
                  }}
                  href="/forgot-password"
                >
                  Forgot Password?
                </NavLink>

                <ButtonPill
                  disabled={loading}
                  variant="outlined"
                  color="secondary"
                  type="submit"
                  sx={{
                    "&:hover": { border: "2px solid #202020" },
                    border: "2px solid #202020",
                    margin: "20px auto",
                    width: "10rem",
                    display: "block",
                    borderRadius: "30px",
                  }}
                >
                  Log In
                </ButtonPill>
              </Form>
              <div className="w-100 text-center mt-2">
                Don't have an account?{" "}
                <NavLink href="/signup">
                  <Button variant="outlined" className="w-100 mt-4">
                    Sign up
                  </Button>
                </NavLink>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </Stack>
  );
}
