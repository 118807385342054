import React, { useEffect } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import TabPanel from "./Structure/Tabs/TabPanel";
import Clients from "./Clients/Clients";
import Library from "./Library";
import { useState } from "react";

const tabStyles = {
  color: "#4D3BE2",
  backgroundColor: "#fff",
  margin: "0 10px 0 10px",
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
  height: "44px",
  minHeight: "44px",
};

export default function Home() {
  const [value, setValue] = useState("groups");

  function handleReload() {
    window.location.reload();
  }

  function handleChange(e, newValue) {
    setValue(newValue);
  }

  return (
    <Box sx={{ position: "relative", top: "-40px" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Tabs"
          TabIndicatorProps={{ hidden: true }}
          sx={{
            zIndex: 201,
            position: "relative",
            left: "100px",
            width: "50%",
            "& button": {
              backgroundColor: "transparent",
              color: "white",
              border: "1px solid #FFFFFF",
              borderRadius: "10px",
              fontFamily: "avenir",
              fontWeight: "700",
              paddingBottom: "20px",
            },
            "& button:active": {
              color: "#4D3BE2",
            },
            "& button:hover": { backgroundColor: "white", color: "#4D3BE2" },
            "& button.Mui-selected": {
              backgroundColor: "white",
              color: "#4D3BE2",
            },
          }}
        >
          <Tab label="groups" value="groups" />
          {/* <Tab label="library" value="library" sx={tabStyles} /> */}
        </Tabs>
        <TabPanel value={value} index="groups">
          <Clients reload={handleReload} />
        </TabPanel>
        <TabPanel value={value} index="library">
          <Library />
        </TabPanel>
      </Box>
    </Box>
  );
}
