import React from "react";
import { Form, Card, NavLink } from "react-bootstrap";
import { Button, Stack, TextField, Typography, Alert } from "@mui/material";
import { useRef, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { ButtonPill } from "./CustomStyled/ButtonPill";

export default function ForgotPassword() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { forgotPassword } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function showError(error) {
    setError(error);
    setTimeout(() => setError(""), 5000);
  }

  async function handleOnSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await forgotPassword(
        emailRef.current.value
        // passwordRef.current.value
      ).then((response) => {
        console.log("response forgotPassword Call", response);
        //navigate("/");
        setLoading(false);
      });
    } catch (error) {
      console.log("errorObj", error);

      let errorString = JSON.stringify(JSON.stringify(error));
      console.log("errorString", errorString);

      if (
        errorString.indexOf("Email has to be verified before first signin" >= 0)
      ) {
        showError("Unverified email. Please verify.");
        setLoading(false);
        return;
      }
    }
  }

  return (
    <Stack spacing={4}>
      <div
        style={{
          marginTop: "150px",
          display: "block",
          width: "480px",
          margin: "150px auto 0px auto",
        }}
      >
        <div
          style={{
            border: "1px solid #DADCE0",
            borderRadius: "20px",
            width: "480px",
            height: "300px",
          }}
        >
          <Typography
            variant="h1"
            style={{
              marginTop: "40px",
            }}
          >
            FORGOT PASSWORD
          </Typography>

          <Card
            style={{
              width: "90%",
              margin: "auto",
              marginTop: "28px",
              border: "none",
            }}
          >
            {error && <Alert severity="error">{error}</Alert>}
            <Card.Body>
              <Form onSubmit={handleOnSubmit}>
                <Stack spacing={2}>
                  <TextField
                    label="Email"
                    inputRef={emailRef}
                    required
                  ></TextField>
                  {/* <TextField
                    label="Enter new password"
                    inputRef={passwordRef}
                    required
                    type="password"
                  ></TextField>{" "}
                  <TextField
                    label="Confirm new password"
                    inputRef={passwordConfirmRef}
                    required
                    type="password"
                  ></TextField> */}
                </Stack>

                <Stack
                  direction="row"
                  sx={{
                    paddingLeft: "40px",
                    marginTop: "20px",
                  }}
                >
                  <ButtonPill
                    disabled={loading}
                    variant="outlined"
                    color="secondary"
                    sx={{
                      "&:hover": { border: "2px solid #202020" },
                      border: "2px solid #202020",
                      margin: "20px auto",
                      width: "10rem",
                      borderRadius: "30px",
                    }}
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    cancel
                  </ButtonPill>
                  <ButtonPill
                    disabled={loading}
                    variant="outlined"
                    color="primary"
                    type="submit"
                    sx={{
                      "&:hover": { border: "2px solid #202020" },
                      border: "2px solid #202020",
                      margin: "20px auto",
                      width: "10rem",
                      borderRadius: "30px",
                    }}
                  >
                    reset
                  </ButtonPill>
                </Stack>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </Stack>
  );
}
